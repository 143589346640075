const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  FORGOT_BEGIN: 'FORGOT_BEGIN',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_ERR: 'FORGOT_ERR',

  RESET_BEGIN: 'RESET_BEGIN',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_ERR: 'RESET_ERR',

  REGISTER_BEGIN: 'REGISTER_BEGIN',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERR: 'REGISTER_ERR',

  ACTIVATION_BEGIN: 'REGISTER_BEGIN',
  ACTIVATION_SUCCESS: 'REGISTER_SUCCESS',
  ACTIVATION_ERR: 'REGISTER_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  forgotBegin: () => {
    return {
      type: actions.FORGOT_BEGIN,
    };
  },

  forgotSuccess: () => {
    return {
      type: actions.FORGOT_SUCCESS,
    };
  },

  forgotErr: (err) => {
    return {
      type: actions.FORGOT_ERR,
      err,
    };
  },

  resetBegin: () => {
    return {
      type: actions.RESET_BEGIN,
    };
  },

  resetSuccess: (data) => {
    return {
      type: actions.RESET_SUCCESS,
      data,
    };
  },

  resetErr: (err) => {
    return {
      type: actions.RESET_ERR,
      err,
    };
  },

  registerBegin: () => {
    return {
      type: actions.RESET_BEGIN,
    };
  },

  registerSuccess: (data) => {
    return {
      type: actions.RESET_SUCCESS,
      data,
    };
  },

  registerErr: (err) => {
    return {
      type: actions.RESET_ERR,
      err,
    };
  },

  activationBegin: () => {
    return {
      type: actions.ACTIVATION_BEGIN,
    };
  },

  activationSuccess: (data) => {
    return {
      type: actions.ACTIVATION_SUCCESS,
      data,
    };
  },

  activationErr: (err) => {
    return {
      type: actions.ACTIVATION_ERR,
      err,
    };
  },
};

export default actions;
