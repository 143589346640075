import Cookies from 'js-cookie';
import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  forgotBegin,
  forgotSuccess,
  forgotErr,
  resetBegin,
  resetErr,
  registerSuccess,
  registerBegin,
  registerErr,
  activationSuccess,
  activationBegin,
  activationErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/login', values);
      // const data = response.error; // Cookies.get('Authorization');
      if (response.data.alert) {
        dispatch(loginErr(response.data.content));
        message[response.data.type](response.data.content);
      } else {
        const dias = values.chk ? 400 : 1;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + dias);
        Cookies.set('Authorization', response.data.token, {
          path: '/',
          expires: expirationDate,
          secure: true,
        });
        // Cookies.set('Authorization', response.data.token);
        Cookies.set('logedIn', true, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('user', response.data.usuario, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('nivel', response.data.nivel, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('nombrecompleto', response.data.nombrecompleto, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('legajo', response.data.legajo, {
          path: '/',
          expires: expirationDate,
        });
        // Cookies.set('user', response.data.usuario);
        dispatch(loginSuccess(response.data));
        callback();
      }
      // Cookies.set('access_token', 'response.data.data.token');
      // Cookies.set('logedIn', true);
      // dispatch(loginSuccess(true));
      // callback();
    } catch (err) {
      // console.log('*** Catch error INICIO ***');
      // console.log(err);
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      // console.log('*** Catch error FIN ***');
      dispatch(loginErr(err));
    }
  };
};

/* const relogin = () => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/relogin');
      // const data = response.error; // Cookies.get('Authorization');
      if (response.data.alert) {
        dispatch(loginErr(response.data.content));
        message[response.data.type](response.data.content);
      } else {
        // Cookies.set('user', response.data.usuario);
        dispatch(loginSuccess(response.data));
      }
      // Cookies.set('access_token', 'response.data.data.token');
      // Cookies.set('logedIn', true);
      // dispatch(loginSuccess(true));
      // callback();
    } catch (err) {
      // console.log('*** Catch error INICIO ***');
      // console.log(err);
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      // console.log('*** Catch error FIN ***');
      dispatch(loginErr(err));
    }
  };
}; */

const forgot = (values) => {
  return async (dispatch) => {
    dispatch(forgotBegin());
    try {
      const response = await DataService.post('/forgot', values);
      // const data = response.error; // Cookies.get('Authorization');
      if (response.data.alert && response.data.type !== 'success') {
        dispatch(forgotErr(response.data.content));
        message[response.data.type](response.data.content);
      } else {
        message[response.data.type](response.data.content, 6);
        dispatch(forgotSuccess(true));
      }
    } catch (err) {
      // console.log('*** Catch error INICIO ***');
      // console.log(err);
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      // console.log('*** Catch error FIN ***');
      dispatch(forgotErr(err));
    }
  };
};

const reset = (values, callback) => {
  return async (dispatch) => {
    dispatch(resetBegin());
    try {
      const url = window.location.href;
      const array = url.split('/');
      const lastsegment = array[array.length - 1];
      const response = await DataService.post(`/recuperar/${lastsegment}`, values);
      // const data = response.error; // Cookies.get('Authorization');

      if (response.data.alert && response.data.type !== 'success') {
        dispatch(resetErr(response.data.content));
        message[response.data.type](response.data.content);
      } else {
        message[response.data.type](response.data.content, 8);
        Cookies.remove('logedIn');
        Cookies.remove('Authorization');
        Cookies.remove('user');
        dispatch(logoutSuccess(false));
        callback();
      }
      // Cookies.set('access_token', 'response.data.data.token');
      // Cookies.set('logedIn', true);
      // dispatch(loginSuccess(true));
      // callback();
    } catch (err) {
      // console.log('*** Catch error INICIO ***');
      // console.log(err);
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      // console.log('*** Catch error FIN ***');
      dispatch(resetErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(registerBegin());
    try {
      const response = await DataService.post('/signup', values);
      if (response.data.alert && response.data.type !== 'success') {
        dispatch(registerErr(response.data.content));
        message[response.data.type](response.data.content);
      } else {
        message[response.data.type](response.data.content, 8);
        dispatch(registerSuccess(false));
      }
    } catch (err) {
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      dispatch(registerErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('Authorization');
      Cookies.remove('user');
      Cookies.remove('legajo');
      Cookies.remove('nombrecompleto');
      Cookies.remove('nivel');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const activation = (callback) => {
  return async (dispatch) => {
    dispatch(activationBegin());
    try {
      const url = window.location.href;
      const array = url.split('/');
      const lastsegment = array[array.length - 1];
      const response = await DataService.post(`/verificarcorreo/${lastsegment}`);
      // const data = response.error; // Cookies.get('Authorization');
      if (response.data.alert && response.data.type !== 'success') {
        message[response.data.type](response.data.content);
        dispatch(activationErr(response.data.content));
      } else {
        const dias = 400;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + dias);
        Cookies.set('Authorization', response.data.token, {
          path: '/',
          expires: expirationDate,
        });
        // Cookies.set('Authorization', response.data.token);
        Cookies.set('logedIn', true, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('user', response.data.usuario, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('nivel', response.data.nivel, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('nombrecompleto', response.data.nombrecompleto, {
          path: '/',
          expires: expirationDate,
        });
        Cookies.set('legajo', response.data.legajo, {
          path: '/',
          expires: expirationDate,
        });
        message[response.data.type](response.data.content, 8);
        dispatch(activationSuccess(true));
        callback();
      }
      // Cookies.set('access_token', 'response.data.data.token');
      // Cookies.set('logedIn', true);
      // dispatch(loginSuccess(true));
      // callback();
    } catch (err) {
      // console.log('*** Catch error INICIO ***');
      dispatch(activationErr(err));
      message.warning('El servidor de aplicación se encuentra en mantenimiento. Por favor intente mas tarde...', 8);
      // console.log('*** Catch error FIN ***');
    }
  };
};

export { login, logOut, register, forgot, reset, activation };
