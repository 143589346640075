import { combineReducers } from 'redux';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { groupDocuReducer, SingleDocuGroupReducer, SingleDocuReducer, docuActionReducer } from './documentos/reducers';
import { usersReducer, SingleUsersReducer, combosReducer } from './users/reducers';
import { docuReducer, firmadosReducer } from './add-documentos/reducers';
import {
  usuarioDocumentosReducer,
  singleUsuarioDocumentoReducer,
  camposReducer,
  descargaRecibos,
} from './view-documentos/reducers';
import { firmaReducer, firmaUserReducer } from './firma/reducers';
import { tableroReducer, tableroRRHHReducer, tableroHabilitacionesReducer } from './tablero/reducers';
import { logsReducer } from './logs/reducers';

const rootReducers = combineReducers({
  notification: readNotificationReducer,
  auth: authReducer,
  docuSingle: SingleDocuReducer,
  docuSingleGroup: SingleDocuGroupReducer,
  docuAction: docuActionReducer,
  groupDocu: groupDocuReducer,
  documentos: docuReducer,
  ChangeLayoutMode,
  users: usersReducer,
  SingleUsers: SingleUsersReducer,
  combos: combosReducer,
  firmados: firmadosReducer,
  usuarioDocumentos: usuarioDocumentosReducer,
  SingleUsuarioDocumento: singleUsuarioDocumentoReducer,
  campos: camposReducer,
  descargaRecibos,
  Firma: firmaUserReducer,
  Firma2: firmaReducer,
  Tablero: tableroReducer,
  TableroRRHH: tableroRRHHReducer,
  TableroHabilitaciones: tableroHabilitacionesReducer,
  Logs: logsReducer,
});

export default rootReducers;
