import { useSelector } from 'react-redux';
import Confetti from 'react-confetti';

function Confetti2() {
  const { confeti } = useSelector((state) => {
    return {
      confeti: state.notification.confeti,
    };
  });
  return (
    <>
      {confeti === 1 ? (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          // numberOfPieces={600}
          // recycle={false}
        />
      ) : null}
    </>
  );
}

export default Confetti2;
