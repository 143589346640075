import Cookies from 'js-cookie';
import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  FORGOT_BEGIN,
  FORGOT_SUCCESS,
  FORGOT_ERR,
  RESET_BEGIN,
  RESET_SUCCESS,
  RESET_ERR,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_ERR,
  ACTIVATION_BEGIN,
  ACTIVATION_SUCCESS,
  ACTIVATION_ERR,
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  user: Number(Cookies.get('user')),
  rol: Cookies.get('nivel'),
  legajo: Number(Cookies.get('legajo')),
  nombrecompleto: Cookies.get('nombrecompleto'),
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: data.user,
        rol: data.nivel,
        legajo: data.legajo,
        nombrecompleto: data.nombrecompleto,
        login: true,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FORGOT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FORGOT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case RESET_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case REGISTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case REGISTER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ACTIVATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case ACTIVATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
