import actions from './actions';

const { AXIOS_READ_BEGIN, AXIOS_READ_SUCCESS, AXIOS_READ_ERR } = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const logsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case AXIOS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case AXIOS_READ_ERR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
};

export { logsReducer };
