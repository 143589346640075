const actions = {
  AXIOS_TABLERO_BEGIN: 'AXIOS_TABLERO_BEGIN',
  AXIOS_TABLERO_SUCCESS: 'AXIOS_TABLERO_SUCCESS',
  AXIOS_TABLERO_ERR: 'AXIOS_TABLERO_ERR',
  AXIOS_TABLERORRHH_BEGIN: 'AXIOS_TABLERORRHH_BEGIN',
  AXIOS_TABLERORRHH_SUCCESS: 'AXIOS_TABLERORRHH_SUCCESS',
  AXIOS_TABLERORRHH_ERR: 'AXIOS_TABLERORRHH_ERR',
  AXIOS_TABLEROHABILITACIONES_BEGIN: 'AXIOS_TABLERORRHH_BEGIN',
  AXIOS_TABLEROHABILITACIONES_SUCCESS: 'AXIOS_TABLERORRHH_SUCCESS',
  AXIOS_TABLEROHABILITACIONES_ERR: 'AXIOS_TABLERORRHH_ERR',

  axiosTableroBegin: () => {
    return {
      type: actions.AXIOS_TABLERO_BEGIN,
    };
  },

  axiosTableroSuccess: (data) => {
    return {
      type: actions.AXIOS_TABLERO_SUCCESS,
      data,
    };
  },

  axiosTableroErr: (err) => {
    return {
      type: actions.AXIOS_TABLERO_ERR,
      err,
    };
  },

  axiosTableroRRHHBegin: () => {
    return {
      type: actions.AXIOS_TABLERORRHH_BEGIN,
    };
  },

  axiosTableroRRHHSuccess: (data) => {
    return {
      type: actions.AXIOS_TABLERORRHH_SUCCESS,
      data,
    };
  },

  axiosTableroRRHHErr: (err) => {
    return {
      type: actions.AXIOS_TABLERORRHH_ERR,
      err,
    };
  },

  axiosTableroHabilitacionesBegin: () => {
    return {
      type: actions.AXIOS_TABLERORRHH_BEGIN,
    };
  },

  axiosTableroHabilitacionesSuccess: (data) => {
    return {
      type: actions.AXIOS_TABLERORRHH_SUCCESS,
      data,
    };
  },

  axiosTableroHabilitacionesErr: (err) => {
    return {
      type: actions.AXIOS_TABLERORRHH_ERR,
      err,
    };
  },
};

export default actions;
