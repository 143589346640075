import actions from './actions';

const {
  AXIOS_ADD_BEGIN,
  AXIOS_ADD_SUCCESS,
  AXIOS_ADD_ERR,

  AXIOS_UPLOAD_BEGIN,
  AXIOS_UPLOAD_SUCCESS,
  AXIOS_UPLOAD_ERR,

  AXIOS_READ_DOCUS_BEGIN,
  AXIOS_READ_DOCUS_SUCCESS,
  AXIOS_READ_DOCUS_ERR,

  AXIOS_READ_FIRMADOS_BEGIN,
  AXIOS_READ_FIRMADOS_SUCCESS,
  AXIOS_READ_FIRMADOS_ERR,

  AXIOS_UPDATE_BEGIN,
  AXIOS_UPDATE_SUCCESS,
  AXIOS_UPDATE_ERR,

  AXIOS_DELETE_BEGIN,
  AXIOS_DELETE_SUCCESS,
  AXIOS_DELETE_ERR,

  AXIOS_SINGLE_DATA_BEGIN,
  AXIOS_SINGLE_DATA_SUCCESS,
  AXIOS_SINGLE_DATA_ERR,

  AXIOS_READ_SUCCESS_COMBOS,
  AXIOS_READ_BEGIN_COMBOS,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const initialSateCombos = {
  areas: [],
  positions: [],
  empresas: [],
  loading: false,
};

const docuReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_UPLOAD_BEGIN:
      return {
        ...state,
        fileLoading: true,
      };

    case AXIOS_UPLOAD_SUCCESS:
      return {
        ...state,
        url: data,
        error: false,
        fileLoading: false,
      };

    case AXIOS_UPLOAD_ERR:
      return {
        ...state,
        error: err,
        fileLoading: false,
      };

    case AXIOS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_ADD_SUCCESS:
      return {
        ...state,
        errores: false,
        loading: false,
      };

    case AXIOS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case AXIOS_READ_DOCUS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_READ_DOCUS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };
    case AXIOS_READ_DOCUS_ERR:
      return {
        ...state,
        data: null,
        error: true,
        loading: false,
      };

    case AXIOS_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_DELETE_SUCCESS:
      return {
        ...state,
        error: false,
        data,
        loading: false,
      };

    case AXIOS_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case AXIOS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_UPDATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };

    case AXIOS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const firmadosReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_READ_FIRMADOS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case AXIOS_READ_FIRMADOS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };
    case AXIOS_READ_FIRMADOS_ERR:
      return {
        ...state,
        data: null,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
const combosReducer = (state = initialSateCombos, action) => {
  const { type } = action;
  switch (type) {
    case AXIOS_READ_SUCCESS_COMBOS:
      return {
        ...action,
        loading: false,
      };
    case AXIOS_READ_BEGIN_COMBOS:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};

const SingleUsersReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_SINGLE_DATA_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case AXIOS_SINGLE_DATA_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        error: false,
        loading: false,
      };

    case AXIOS_SINGLE_DATA_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { docuReducer, SingleUsersReducer, combosReducer, firmadosReducer };
