import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const DemoFive = lazy(() => import('../../container/dashboard/DemoFive'));
const DemoSix = lazy(() => import('../../container/dashboard/DemoSix'));
const DemoNine = lazy(() => import('../../container/dashboard/DemoNine'));
// const Users = lazy(() => import('../../container/users/Users'));
// const Team = lazy(() => import('../../container/users/Team'));
// const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes() {
  return (
    <Routes>
      <Route path="tablero" element={<DemoFive />} />
      <Route path="tableroHab" element={<DemoSix />} />
      <Route path="home" element={<DemoNine />} />
    </Routes>
  );
}

export default DashboardRoutes;
