import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import { Avatar } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Notification from './Notification';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };

  const { nombre, legajo } = useSelector((state) => {
    return {
      // user: state.auth.user,
      nombre: state.auth.nombrecompleto,
      legajo: state.auth.legajo,
    };
  });

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/profileImage.png')} alt="" />
          <figcaption>
            <Heading as="h5">{nombre}</Heading>
            <p>{legajo}</p>
          </figcaption>
        </figure>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#" color="rgb(70,70,70)">
          <UilSignout /> Deslogearse
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Notification />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src={require('../../../static/img/avatar/user.png')} />
            <span className="ninjadash-nav-actions__author--name">{nombre}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
