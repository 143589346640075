const actions = {
  AXIOS_FIRMA_BEGIN: 'AXIOS_FIRMA_BEGIN',
  AXIOS_FIRMA_SUCCESS: 'AXIOS_FIRMA_SUCCESS',
  AXIOS_FIRMA_ERR: 'AXIOS_FIRMA_ERR',

  AXIOS_SINGLE_FIRMA_BEGIN: 'AXIOS_SINGLE_FIRMA_BEGIN',
  AXIOS_SINGLE_FIRMA_SUCCESS: 'AXIOS_SINGLE_FIRMA_SUCCESS',
  AXIOS_SINGLE_FIRMA_ERR: 'AXIOS_SINGLE_FIRMA_ERR',

  axiosFirmaBegin: () => {
    return {
      type: actions.AXIOS_FIRMA_BEGIN,
    };
  },

  axiosFirmaSuccess: (data) => {
    return {
      type: actions.AXIOS_FIRMA_SUCCESS,
      data,
    };
  },

  axiosFirmaErr: (err) => {
    return {
      type: actions.AXIOS_FIRMA_ERR,
      err,
    };
  },

  axiosSingleDataBegin: () => {
    return {
      type: actions.AXIOS_SINGLE_FIRMA_BEGIN,
    };
  },

  axiosSingleDataSuccess: (data) => {
    return {
      type: actions.AXIOS_SINGLE_FIRMA_SUCCESS,
      data,
    };
  },

  axiosSingleDataErr: (err) => {
    return {
      type: actions.AXIOS_SINGLE_FIRMA_ERR,
      err,
    };
  },
};

export default actions;
