const actions = {
  AXIOS_READ_BEGIN: 'AXIOS_READ_BEGIN',
  AXIOS_READ_SUCCESS: 'AXIOS_READ_SUCCESS',
  AXIOS_READ_ERR: 'AXIOS_READ_ERR',

  axiosReadBegin: () => {
    return {
      type: actions.AXIOS_READ_BEGIN,
    };
  },

  axiosReadSuccess: (data) => {
    return {
      type: actions.AXIOS_READ_SUCCESS,
      data,
    };
  },

  axiosReadErr: (err) => {
    return {
      type: actions.AXIOS_READ_ERR,
      err,
    };
  },
};
export default actions;
