import actions from './actions';

const {
  AXIOS_FIRMA_BEGIN,
  AXIOS_FIRMA_SUCCESS,
  AXIOS_FIRMA_ERR,

  AXIOS_SINGLE_FIRMA_BEGIN,
  AXIOS_SINGLE_FIRMA_SUCCESS,
  AXIOS_SINGLE_FIRMA_ERR,
} = actions;

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
  carga: '',
};

const firmaReducer = (state = initialStateSingle, action) => {
  const { type } = action;
  switch (type) {
    case AXIOS_FIRMA_BEGIN:
      return {
        loading: true,
        carga: '',
      };

    case AXIOS_FIRMA_SUCCESS:
      return {
        loading: false,
        carga: 'ok',
      };

    case AXIOS_FIRMA_ERR:
      return {
        loading: false,
        carga: '',
      };
    default:
      return state;
  }
};

const firmaUserReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case AXIOS_SINGLE_FIRMA_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case AXIOS_SINGLE_FIRMA_SUCCESS:
      return {
        ...initialStateSingle,
        firma: data,
        error: false,
        loading: false,
      };

    case AXIOS_SINGLE_FIRMA_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { firmaReducer, firmaUserReducer };
