import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import Pages from './pages';

import withAdminLayout from '../../layout/withAdminLayout';

const DocumentosApp = lazy(() => import('../../container/documentos/DocumentosApp'));
const Usuarios = lazy(() => import('../../container/users/Users'));
const Logs = lazy(() => import('../../container/logs/Logs'));
const AddUser = lazy(() => import('../../container/users/Add'));
const AddUserBulk = lazy(() => import('../../container/users/AddBulk'));
const EditUser = lazy(() => import('../../container/users/Edit'));
const DocsUser = lazy(() => import('../../container/users/Docs'));
const Documento = lazy(() => import('../../container/add-documentos/Documentos'));
const AddDocumento = lazy(() => import('../../container/add-documentos/Add'));
const EditDocumento = lazy(() => import('../../container/add-documentos/Edit'));
const ViewDocumento = lazy(() => import('../../container/view-documentos/ViewDocumentos'));
const AddFirma = lazy(() => import('../../container/firma/Add'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path="app/documentos/*" element={<DocumentosApp />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="users/" element={<Usuarios />} />
        <Route path="users/add/*" element={<AddUser />} />
        <Route path="users/addbulk/*" element={<AddUserBulk />} />
        <Route path="users/edit/:id" element={<EditUser />} />
        <Route path="users/docs/:name/:id/:tipo" element={<DocsUser />} />
        <Route path="cargardocumentos/" element={<Documento />} />
        <Route path="cargardocumentos/add/*" element={<AddDocumento />} />
        <Route path="cargardocumentos/edit/:id" element={<EditDocumento />} />
        <Route path="cargardocumentos/view/:name/:id/:tipo" element={<ViewDocumento />} />
        <Route path="logs/" element={<Logs />} />
        <Route path="firma/add/*" element={<AddFirma />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
