import {
  UilUsersAlt,
  UilBooks,
  UilFileDownload,
  UilChartPieAlt,
  UilChartBar,
  UilCloudUpload,
  UilListUl,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, rol } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      rol: state.auth.rol,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  let items = [];

  if (rol) {
    // const usuario = rol.indexOf('usuario') !== -1;
    const rrhh = rol.indexOf('rrhh') !== -1;
    // const admin = rol.indexOf('admin') !== -1;
    if (rrhh) {
      items = [
        ...items,
        getItem(
          !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Administración RRHH')}</NavTitle>,
          'administracion RRHH2',
          null,
          null,
          'group',
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/tablero/`}>
            {t('Tablero')} {t('RRHH')}
          </NavLink>,
          'tablero RRHH',
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/tablero/`}>
              <UilChartBar />
            </NavLink>
          ),
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/cargardocumentos/`}>
            {t('Documentos')}
          </NavLink>,
          'Documentos',
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/cargardocumentos/`}>
              <UilCloudUpload />
            </NavLink>
          ),
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/users/`}>
            {t('Usuarios')}
          </NavLink>,
          'usuarios',
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/users/`}>
              <UilUsersAlt />
            </NavLink>
          ),
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/logs/`}>
            {t('Registro')} {t('de')} {t('Actividad')}
          </NavLink>,
          'logs',
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/logs/`}>
              <UilListUl />
            </NavLink>
          ),
        ),
      ];
    }
  }
  const habilitacion = rol.indexOf('habilitacion') !== -1;

  if (habilitacion) {
    items = [
      ...items,
      getItem(
        !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Administración Habilitaciones')}</NavTitle>,
        'administracion Habilitaciones',
        null,
        null,
        'group',
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/tableroHab/`}>
          {t('Tablero')} {t('Habilitaciones')}
        </NavLink>,
        'tablero Habilitaciones',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/tableroHab/`}>
            <UilChartBar />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cargardocumentos/`}>
          {t('Documentos')}
        </NavLink>,
        'Documentos',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/cargardocumentos/`}>
            <UilCloudUpload />
          </NavLink>
        ),
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/logs/`}>
          {t('Registro')} {t('de')} {t('Actividad')}
        </NavLink>,
        'logs',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/logs/`}>
            <UilListUl />
          </NavLink>
        ),
      ),
    ];
  }

  const usuario = true;
  if (usuario) {
    items = [
      ...items,
      getItem(
        !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Administración')}</NavTitle>,
        'administracion',
        null,
        null,
        'group',
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/home/`}>
          {t('Tablero')} {t('Principal')}
        </NavLink>,
        'tablero',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/home/`}>
            <UilChartPieAlt />
          </NavLink>
        ),
      ),
      getItem(
        !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Documentación')}</NavTitle>,
        'documentaciont',
        null,
        null,
        'group',
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/app/documentos/private/`}>
          {t('Descarga')} {t('&')} {t('Firma')}
        </NavLink>,
        'documentacion',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/app/documentos/private/`}>
            <UilFileDownload />
          </NavLink>
        ),
      ),
      getItem(
        !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Páginas')}</NavTitle>,
        'paginas',
        null,
        null,
        'group',
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/pages/termCondition`}>
          {t('Términos')} {t('&')} {t('Condiciones')}
        </NavLink>,
        'termcondition',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/termCondition`}>
            <UilBooks />
          </NavLink>
        ),
      ),
    ];
  }
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
