import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const initialStateGroupDocu = {
  data: [],
  loading: false,
  error: null,
};

const initialStateGroup = {
  data: [],
  loading: false,
  error: null,
};

const initialStateUpdate = {
  data: [],
  loading: false,
  error: null,
};

const {
  SINGLE_DOCU_BEGIN,
  SINGLE_DOCU_SUCCESS,
  SINGLE_DOCU_ERR,

  SINGLE_GROUP_DOCU_BEGIN,
  SINGLE_GROUP_DOCU_SUCCESS,
  SINGLE_GROUP_DOCU_ERR,

  UPDATE_GROUP_DOCU_BEGIN,
  UPDATE_GROUP_DOCU_SUCCESS,
  UPDATE_GROUP_DOCU_ERR,

  UPDATE_PRIVET_DOCU_BEGIN,
  UPDATE_PRIVET_DOCU_SUCCESS,
  UPDATE_PRIVET_DOCU_ERR,

  DESCARGAR_DOCU_BEGIN,
  DESCARGAR_DOCU_SUCCESS,
  DESCARGAR_DOCU_ERR,

  FIRMAR_DOCU_BEGIN,
  FIRMAR_DOCU_SUCCESS,
  FIRMAR_DOCU_ERR,
} = actions;

const docuReducer = (state = initialStateUpdate, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_PRIVET_DOCU_BEGIN:
      return {
        ...initialStateUpdate,
        loading: true,
      };
    case UPDATE_PRIVET_DOCU_SUCCESS:
      return {
        ...initialStateUpdate,
        data,
        loading: false,
      };
    case UPDATE_PRIVET_DOCU_ERR:
      return {
        ...initialStateUpdate,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const SingleDocuReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_DOCU_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_DOCU_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_DOCU_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const groupDocuReducer = (state = initialStateGroupDocu, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_GROUP_DOCU_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case UPDATE_GROUP_DOCU_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_GROUP_DOCU_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const SingleDocuGroupReducer = (state = initialStateGroup, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_GROUP_DOCU_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_GROUP_DOCU_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SINGLE_GROUP_DOCU_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const docuActionReducer = (state = initialStateGroup, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DESCARGAR_DOCU_BEGIN:
      return {
        ...state,
        loading: true,
        descarga: true,
      };
    case DESCARGAR_DOCU_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        descarga: true,
      };
    case DESCARGAR_DOCU_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        descarga: true,
      };
    case FIRMAR_DOCU_BEGIN:
      return {
        ...state,
        loading: true,
        descarga: false,
      };
    case FIRMAR_DOCU_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        descarga: true,
      };
    case FIRMAR_DOCU_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        descarga: false,
      };
    default:
      return state;
  }
};

export { SingleDocuReducer, docuReducer, groupDocuReducer, SingleDocuGroupReducer, docuActionReducer };
