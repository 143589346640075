const actions = {
  SINGLE_DOCU_BEGIN: 'SINGLE_DOCU_BEGIN',
  SINGLE_DOCU_SUCCESS: 'SINGLE_DOCU_SUCCESS',
  SINGLE_DOCU_ERR: 'SINGLE_DOCU_ERR',

  SINGLE_GROUP_DOCU_BEGIN: 'SINGLE_GROUP_DOCU_BEGIN',
  SINGLE_GROUP_DOCU_SUCCESS: 'SINGLE_GROUP_DOCU_SUCCESS',
  SINGLE_GROUP_DOCU_ERR: 'SINGLE_GROUP_DOCU_ERR',

  UPDATE_GROUP_DOCU_BEGIN: 'UPDATE_GROUP_DOCU_BEGIN',
  UPDATE_GROUP_DOCU_SUCCESS: 'UPDATE_GROUP_DOCU_SUCCESS',
  UPDATE_GROUP_DOCU_ERR: 'UPDATE_GROUP_DOCU_ERR',

  UPDATE_PRIVET_DOCU_BEGIN: 'UPDATE_PRIVET_DOCU_BEGIN',
  UPDATE_PRIVET_DOCU_SUCCESS: 'UPDATE_PRIVET_DOCU_SUCCESS',
  UPDATE_PRIVET_DOCU_ERR: 'UPDATE_PRIVET_DOCU_ERR',

  DESCARGAR_DOCU_BEGIN: 'DESCARGAR_DOCU_BEGIN',
  DESCARGAR_DOCU_SUCCESS: 'DESCARGAR_DOCU_SUCCESS',
  DESCARGAR_DOCU_ERR: 'DESCARGAR_DOCU_ERR',

  FIRMAR_DOCU_BEGIN: 'FIRMAR_DOCU_BEGIN',
  FIRMAR_DOCU_SUCCESS: 'FIRMAR_DOCU_SUCCESS',
  FIRMAR_DOCU_ERR: 'FIRMAR_DOCU_ERR',

  singleDocuBegin: () => {
    return {
      type: actions.SINGLE_DOCU_BEGIN,
    };
  },

  singleDocuSuccess: (data) => {
    return {
      type: actions.SINGLE_DOCU_SUCCESS,
      data,
    };
  },

  singleDocuErr: (err) => {
    return {
      type: actions.SINGLE_DOCU_ERR,
      err,
    };
  },

  singleGroupDocuBegin: () => {
    return {
      type: actions.SINGLE_GROUP_DOCU_BEGIN,
    };
  },

  singleGroupDocuSuccess: (data) => {
    return {
      type: actions.SINGLE_GROUP_DOCU_SUCCESS,
      data,
    };
  },

  singleGroupDocuErr: (err) => {
    return {
      type: actions.SINGLE_GROUP_DOCU_ERR,
      err,
    };
  },

  updateGroupDocuBegin: () => {
    return {
      type: actions.UPDATE_GROUP_DOCU_BEGIN,
    };
  },

  updateGroupDocuSuccess: (data) => {
    return {
      type: actions.UPDATE_GROUP_DOCU_SUCCESS,
      data,
    };
  },

  updateGroupDocuErr: (err) => {
    return {
      type: actions.UPDATE_GROUP_DOCU_ERR,
      err,
    };
  },

  updatePrivetDocuBegin: () => {
    return {
      type: actions.UPDATE_PRIVET_DOCU_BEGIN,
    };
  },

  updatePrivetDocuSuccess: (data) => {
    return {
      type: actions.UPDATE_PRIVET_DOCU_SUCCESS,
      data,
    };
  },

  updatePrivetDocuErr: (err) => {
    return {
      type: actions.UPDATE_PRIVET_DOCU_ERR,
      err,
    };
  },

  descargarDocuBegin: () => {
    return {
      type: actions.DESCARGAR_DOCU_BEGIN,
    };
  },

  descargarDocuSuccess: () => {
    return {
      type: actions.DESCARGAR_DOCU_SUCCESS,
    };
  },

  descargarDocuErr: (err) => {
    return {
      type: actions.DESCARGAR_DOCU_ERR,
      err,
    };
  },

  firmarDocuBegin: () => {
    return {
      type: actions.FIRMAR_DOCU_BEGIN,
    };
  },

  firmarDocuSuccess: () => {
    return {
      type: actions.V_DOCU_SUCCESS,
    };
  },

  firmarDocuErr: (err) => {
    return {
      type: actions.FIRMAR_DOCU_ERR,
      err,
    };
  },
};

export default actions;
