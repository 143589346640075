import UilHdd from '@iconscout/react-unicons/icons/uil-bill';
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { UserActionDropDown } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { readNotificationList } from '../../../redux/notification/actionCreator';

const NotificationBox = React.memo(() => {
  const { rtl, notificaciones, notificacion } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      notificaciones: state.Tablero.notificaciones,
      notificacion: state.notification.data,
    };
  });
  /* if (notificacion[0]) {
    let cuando = `hace ${moment.utc(notificacion[0].CREADO).local().startOf('seconds').fromNow()}`;
    cuando = cuando.replace('ago', '');
    cuando = cuando.replace('a few', 'algunos');
    cuando = cuando.replace('second', 'segundo');
    cuando = cuando.replace('minute', 'minuto');
    cuando = cuando.replace('hour', 'hora');
    cuando = cuando.replace('day', 'día');
    console.log(cuando);
  } */

  // console.log(moment.utc('2019-12-04 12:00:24').local().startOf('seconds').fromNow());

  const dispatch = useDispatch();

  useEffect(() => {
    if (readNotificationList) {
      dispatch(readNotificationList());
    }
  }, [dispatch]);

  function renderThumb({ style }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style }) {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div style={{ ...style, ...customStyle }} />;
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <UserActionDropDown className="ninjadash-top-dropdown">
      <Heading as="h5" className="ninjadash-top-dropdown__title">
        <span className="title-text">Notificaciones</span>
        <Badge className="badge-success" count={notificaciones} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
        renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
      >
        <ul className="ninjadash-top-dropdown__nav notification-list">
          {notificacion?.length > 0 &&
            notificacion
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((notis) => {
                const { CODIGO, DETALLE, CREADO, ID, LEIDO } = notis;
                const id = ID;
                let cuando = `hace ${moment.utc(CREADO).local().startOf('seconds').fromNow()}`;
                cuando = cuando.replace('ago', '');
                cuando = cuando.replace('a few', 'algunos');
                cuando = cuando.replace('second', 'segundo');
                cuando = cuando.replace('minute', 'minuto');
                cuando = cuando.replace('hour', 'hora');
                cuando = cuando.replace('day', 'día');
                cuando = cuando.replace('months', 'meses');
                cuando = cuando.replace('month', 'mes');
                cuando = cuando.replace('year', 'año');
                cuando = cuando.replace(' a ', ' 1 ');
                cuando = cuando.replace(' an ', ' 1 ');
                return (
                  <li key={id}>
                    <div className="ninjadash-top-dropdown__content notifications">
                      <div className="notification-icon bg-primary">
                        <UilHdd />
                      </div>
                      <div className="notification-content d-flex">
                        <div className="notification-text">
                          <Heading as="h5">
                            <span>{CODIGO}</span> {DETALLE}
                          </Heading>
                          <p>{cuando}</p>
                        </div>
                        <div className="notification-status">{LEIDO === 0 && <Badge dot />}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
      </Scrollbars>
    </UserActionDropDown>
  );

  return (
    <div className="ninjadash-nav-actions__item ninjadash-nav-actions__notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge color="green" count={notificaciones} size="small" offset={[-8, -5]}>
          <Link to="#" className="ninjadash-nav-action-link">
            <ReactSVG src={require('../../../static/img/icon/bell.svg').default} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
});

export default NotificationBox;
