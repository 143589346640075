import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { readNotificationBegin, readNotificationSuccess, readNotificationErr } = actions;

const readNotificationList = () => {
  return async (dispatch) => {
    try {
      await dispatch(readNotificationBegin());
      const query = await DataService.get(`/notificaciones`);
      if (query.data.data || query.data.confeti) {
        await dispatch(readNotificationSuccess(query.data.data, query.data.confeti));
      }
    } catch (err) {
      await dispatch(readNotificationErr(err));
    }
  };
};

export { readNotificationList };
